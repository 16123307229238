<template>
  <div>
    <header class="page-header">
      <PageTitle/>
      <div class="buttons">
        <router-link v-if="checkPermissions(['loyalty_upgrade.create'])" :to="{ name: 'admin.loyaltyupgrade' }" class="btn btn-add">
          <span>{{ $t('Loyalty upgrade toevoegen') }}</span>
        </router-link>
      </div>
    </header>

    <div class="main-content">
      <div class="pane table-pane">
        <div class="table-wrapper">
          <div class="table-element">
            <table class="loyalty-upgrades">
              <thead>
              <tr>
                <th class="id">{{ $t('ID') }}</th>
                <th>{{ $t('Titel') }}</th>
                <th>{{ $t('Type') }}</th>
                <th>{{ $t('Scores') }}</th>
                <th>{{ $t('Aangemaakt') }}</th>
              </tr>
              </thead>

              <tbody v-if="loyaltyUpgrades.length > 0">
              <tr v-for="loyaltyUpgrade in loyaltyUpgrades" :key="loyaltyUpgrade.id" @click="$router.push({name: 'admin.loyaltyupgrade', params: {id: loyaltyUpgrade.id}})" @contextmenu.prevent.stop="loyaltyUpgradeRightClick($event, loyaltyUpgrade)">
                <td class="id">{{ loyaltyUpgrade.id }}</td>
                <td>{{ getTranslation(loyaltyUpgrade).title }}</td>
                <td>{{ upgradePointTypesSwitch[loyaltyUpgrade.type] }}</td>
                <td>{{ loyaltyUpgrade.scores.toString() }}</td>
                <td>{{ datetime.fromISO(loyaltyUpgrade.created).setLocale('nl').toFormat('dd/LL/yyyy') }}</td>
              </tr>
              </tbody>
            </table>
          </div>

          <div class="pagination">
            <div class="results">
              {{ $t(`${pageTotal.min} - ${pageTotal.max} van ${total} loyalty upgrades`) }}
            </div>

            <div class="buttons">
              <button :disabled="page === 0" class="btn-inverse" @click="prevPage()">Prev</button>
              <button :disabled="loyaltyUpgrades.length !== perPage" class="btn-inverse" @click="nextPage()">Next</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <vue-simple-context-menu ref="vueSimpleContextMenu" :options="contextMenuOptions" element-id="vueSimpleContextMenu" @option-clicked="contextOptionClicked"></vue-simple-context-menu>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import { notification } from '@/notifications'
import { ApiService } from '@/services/admin/api.service'
import PageTitle from '@/components/admin/PageTitle'

export default {
  components: { PageTitle },
  data: function () {
    return {
      datetime: DateTime,
      search: '',
      contextMenuOptions: [{ id: 'deleteLoyaltyUpgrade', name: 'Loyalty upgrade verwijderen' }],
      upgradePointTypes: {
        volume_revenue_score: 1,
        volume_growth_score: 2,
        volume_seniority_score: 3,
        quality_payment_behavior_score: 4,
        quality_training_staff_score: 5,
        quality_complaints_returns_after_sales: 6,
        quality_dna_match: 7,
        sales_marketing_followup_leads_score: 8,
        sales_marketing_loyalty: 9,
        sales_marketing_visibility_score: 10,
        quality_payment_method_score: 11,
        quality_followed_products_score: 12
      },
      upgradePointTypesSwitch: {}
    }
  },
  async mounted () {
    await this.fetchLoyaltyUpgrades(this.page)
    this.upgradePointTypesSwitch = Object.fromEntries(Object.entries(this.upgradePointTypes).map(a => a.reverse()))
  },
  methods: {
    loyaltyUpgradeRightClick: async function (event, loyaltyUpgrade) {
      if (this.checkPermissions(['loyalty_upgrade.delete'])) {
        this.$refs.vueSimpleContextMenu.showMenu(event, loyaltyUpgrade)
      }
    },
    contextOptionClicked: async function (event) {
      await this.deleteLoyaltyUpgrade(event.item.id)
      await this.$store.dispatch('LoyaltyUpgradesModule/fetchLoyaltyUpgrades')
    },
    deleteLoyaltyUpgrade: async function (loyaltyUpgradeId) {
      ApiService.deleteLoyaltyUpgrade(loyaltyUpgradeId).then((res) => {
        notification('Loyalty upgrade succesvol verwijderd.')
      })
    },
    prevPage: async function () {
      if (this.page > 0) {
        await this.fetchLoyaltyUpgrades(this.page - 1)
      }
    },
    nextPage: async function () {
      if (this.loyaltyUpgrades.length === this.perPage) {
        await this.fetchLoyaltyUpgrades(this.page + 1)
      }
    },
    async fetchLoyaltyUpgrades (page) {
      await this.$store.dispatch('LoyaltyUpgradesModule/fetchLoyaltyUpgrades', { page: page, perPage: this.perPage })
    }
  },
  computed: {
    total: function () {
      return this.$store.state.LoyaltyUpgradesModule.total
    },
    page: function () {
      return this.$store.state.LoyaltyUpgradesModule.page
    },
    perPage: function () {
      return this.$store.state.LoyaltyUpgradesModule.perPage
    },
    loyaltyUpgrades: function () {
      return this.$store.getters['LoyaltyUpgradesModule/getLoyaltyUpgrades']
    },
    pageTotal: function () {
      let offsetMax = this.perPage * (this.page + 1)
      const offsetMin = offsetMax - this.perPage + 1

      if (offsetMax > this.total) {
        offsetMax = this.total
      }

      return { min: offsetMin, max: offsetMax }
    }
  }
}
</script>
